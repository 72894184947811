import React from "react";
import ServiceCardWrap from "./ServiceCardWrap";
// import ClientTestimonial from "./ClientTestimonial";

function ServiceWrap() {
  return (
    <>
      <ServiceCardWrap />
      {/* <ClientTestimonial /> */}
    </>
  );
}

export default ServiceWrap;
