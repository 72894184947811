import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems from "./ServiceDetailsItems";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  How we work on your project?
                </h3>
                <p>
                  Our software development process involves three main steps:
                  brainstorming and wireframing to plan and conceptualize the
                  project, UI design to create visually appealing interfaces,
                  and final development and testing to bring the project to
                  fruition. By following these steps, we ensure that each
                  project is tailored to our clients' needs and meets the
                  highest standards of quality and functionality.
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              {/* <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      UI/UX Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Web Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      App Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Software Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Web 3.0 Solutions
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Employment Agency
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div> */}

              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Whatsapp Us</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+852 6447 8620">+852 6447 8620</a>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
