import React from 'react'
import AnimateLine from '../../common/AnimateLine'
import Breadcrumb from '../../common/Breadcrumb'
import SubscribArea from '../../common/SubscribArea'

function Pricing() {
  return (
    <div className="coolblock-wrap">
        <AnimateLine />
        <Breadcrumb pageTitle="Pricing Plan" pageName="Pricing" />
        <SubscribArea />
      </div>
  )
}

export default Pricing