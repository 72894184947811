import React from "react";
import { Link } from "react-router-dom";
import coolblocLogo from "../../../assets/img/COOLBLOCK_LOGO.png";
import coolblockDiscussion from "../../../assets/img/coolblock-discussion.png";

function HeroArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img src={coolblockDiscussion} alt="HeroBanner" />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li>
              <a href="https://www.facebook.com/profile.php?id=100087318709061&mibextid=LQQJ4d">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/coolblocktech/">Instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/coolblocktechnology/">
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>
                    Creative &amp; Minimal<span>It Agency.</span>
                  </h1>
                  <p>
                    Our IT agency is dedicated to provide exceptional software
                    development services, including website and app development,
                    E-commerce and CRM platforms development. Additionally, we
                    strive to become a leading blockchain solution provider in
                    the areas of cyber security and supply chain management.
                    Choose us for your software development needs, and
                    experience the difference that our expertise and dedication
                    can make for your business.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/about`}
                        onClick={scrollTop}
                      >
                        About Us
                      </Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/service`}
                        onClick={scrollTop}
                      >
                        Our Services
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroArea;
