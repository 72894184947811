const jobs = [
  {
    title: "Frontend Software Developer",
    description:
      "We are seeking a skilled Frontend Developer to join our team to help us create intuitive and visually appealing web applications. The successful candidate will be responsible for the development and implementation of user-facing features, working closely with the design and back-end development teams to ensure seamless integration and optimal user experience.",
    responsibilities: [
      "Develop and implement user-friendly web applications, including the creation of high-quality, pixel-perfect interfaces, interactive components, and custom features;",
      "Collaborate with designers, back-end developers, and other stakeholders to ensure seamless integration and optimal performance of user-facing features;",
      "Build reusable code and libraries for future use;",
      "Ensure cross-browser and cross-platform compatibility; and",
      "Stay up-to-date with emerging trends and technologies in frontend development and contribute ideas and suggestions to improve our development process and the user experience of our products.",
    ],
    key_qualifications: [
      "Bachelor's degree in Computer Science or a related field, or equivalent work experience;",
      "2+ years of experience in frontend web development, including proficiency in HTML, CSS, JavaScript, and related frameworks such as React or Vue.js",
      "Experience with responsive design, CSS preprocessors such as Sass or LESS, and version control systems such as Git;",
      "Excellent problem-solving skills, attention to detail, and ability to work effectively in a collaborative team environment;",
      "Strong communication skills and ability to explain technical concepts to non-technical stakeholders.",
    ],
    working_hours: "Full-time",
    location: "Kwun Tong",
    salary: "Competitive Salary Package",
    skills: [
      "Node.js",
      "JavaScript",
      "TypeScript",
      "React.js",
      "React Native",
      "Vue.js",
    ],
  },
  {
    title: "Backend Software Developer",
    description:
      "We are seeking a skilled Backend Developer to join our team. As a Backend Developer, you will be responsible for developing and maintaining server-side web application logic and integrations with databases, APIs, and other systems. You will work closely with front-end developers and other stakeholders to ensure the smooth operation of web applications and to implement new features and functionality. The ideal candidate will have a strong understanding of software development best practices and experience with relevant programming languages and frameworks.",
    responsibilities: [
      "Develop server-side web application logic using appropriate technologies and programming languages;",
      "Build and maintain integrations with databases, APIs, and other systems;",
      "Collaborate with front-end developers, UX designers, and other stakeholders to ensure smooth operation of web applications;",
      "Participate in code reviews and implement changes based on feedback;",
      "Ensure the scalability and reliability of web applications; and",
      "Stay up-to-date with emerging trends and best practices in backend development.",
    ],
    key_qualifications: [
      "Bachelor's degree in Computer Science or related field, or equivalent work experience;",
      "Proven experience as a Backend Developer or similar role with 3+ years of experience in backend development;",
      "Strong understanding of software development principles and best practices",
      "Proficient in one or more programming languages such as Java, Python, or Node.js",
      "Familiarity with web application frameworks such as Spring or Express;",
      "Experience with databases such as PostgreSQL and MongoDB;",
      "Understanding of RESTful API design principles and experience building and consuming APIs;",
      "Knowledge of version control systems such as Git; and",
      "Excellent problem-solving skills and attention to detail",
    ],
    working_hours: "Full-time",
    location: "Kwun Tong",
    salary: "Competitive Salary Package",
    skills: [
      "Java",
      "Spring Boot",
      "Node.js",
      "Express",
      "PostgreSQL",
      "MongoDB",
    ],
  },
  // {
  //   title: "Full Stack Software Engineer",
  //   description:
  //     "We are seeking a Full Stack Software Engineer to join our team and play a crucial role in developing and maintaining our web applications. The ideal candidate should be passionate about software development and should possess the technical expertise required to deliver high-quality software solutions. You will be responsible for developing, testing, and deploying applications while ensuring optimal performance, scalability, and maintainability.",
  //   responsibilities: [
  //     "Collaborate with cross-functional teams to develop software solutions that meet business requirements;",
  //     "Design and develop robust, scalable, and maintainable web applications using technologies such as Node.js, React, and MongoDB;",
  //     "Write clean, modular, and well-documented code;",
  //     "Optimize applications for maximum speed and scalability;",
  //     "Conduct code reviews and maintain code quality standards;",
  //     "Collaborate with DevOps to ensure proper deployment and scaling of applications;",
  //     "Work with the QA team to ensure software quality and test coverage;",
  //     "Troubleshoot and resolve software defects and issues; and",
  //     "Stay up-to-date with emerging trends and technologies in software development.",
  //   ],
  //   key_qualifications: [
  //     "Bachelor's degree in Computer Science, Software Engineering or a related field;",
  //     "3+ years of experience in software development with a focus on Full Stack web development;",
  //     "Experience with JavaScript, Node.js, React, Angular, MongoDB, and SQL databases;",
  //     "Strong understanding of software development principles such as OOP, design patterns, and data structures;",
  //     "Experience with agile development methodologies;",
  //     "Strong problem-solving and analytical skills;",
  //     "Excellent written and verbal communication skills;",
  //     "Ability to work independently and in a team environment;",
  //     "Strong attention to detail and ability to multitask; and",
  //     "Experience with DevOps tools such as Jenkins, Docker, and Kubernetes is a plus.",
  //   ],
  //   working_hours: "Full-time",
  //   location: "Kwun Tong",
  //   salary: "Competitive Salary Package",
  //   skills: [
  //     "Node.js",
  //     "JavaScript",
  //     "TypeScript",
  //     "React.js",
  //     "React Native",
  //     "Vue.js",
  //     "Java",
  //     "Spring Boot",
  //     "Express",
  //     "PostgreSQL",
  //     "MongoDB",
  //   ],
  // },
  {
    title: "DevOps Engineer",
    description:
      "We are seeking a skilled DevOps Engineer to join our team. As a DevOps Engineer, you will be responsible for developing and implementing our deployment pipelines, infrastructure automation, monitoring and scaling of our systems. You will work closely with development and operations teams to ensure the smooth and reliable operation of our systems. The ideal candidate will have experience in infrastructure automation, cloud computing, and continuous integration and deployment tools.",
    responsibilities: [
      "Develop, implement and maintain our deployment pipelines;",
      "Automate infrastructure deployment, scaling and monitoring using tools like Terraform, Ansible, or CloudFormation;",
      "Collaborate with development and operations teams to ensure the smooth operation of our systems;",
      "Continuously improve the performance, reliability, and security of our systems;",
      "Develop and maintain documentation related to infrastructure and deployment processes; and",
      "Stay up-to-date with emerging trends and best practices in DevOps.",
    ],
    key_qualifications: [
      "Bachelor's degree in Computer Science or related field, or equivalent work experience;",
      "Proven experience as a DevOps Engineer or similar role;",
      "Strong understanding of software development principles and best practices;",
      "Experience with cloud computing platforms such as AWS, Azure, or Google Cloud Platform;",
      "Experience with automation tools like Terraform, Ansible, or CloudFormation;",
      "Familiarity with containerization and container orchestration platforms like Docker, Kubernetes, or ECS;",
      "Experience with continuous integration and deployment tools like Jenkins, CircleCI, or GitLab;",
      "Knowledge of scripting languages like Python, Bash, or Ruby;",
      "Understanding of networking, security and web server technologies; and",
      "Excellent problem-solving skills and attention to detail.",
    ],
    working_hours: "Full-time",
    location: "Kwun Tong",
    salary: "Competitive Salary Package",
    skills: ["AWS", "Terraform", "Docker", "Kubernetes"],
  },
  {
    title: "Marketing Manager",
    description:
      "The Marketing Manager is responsible for developing, implementing, and managing marketing campaigns that promote a company's products and/or services. They must be able to work collaboratively with the sales team and other departments to create effective marketing strategies that drive business growth and increase revenue.",
    responsibilities: [
      "Develop and implement marketing strategies that align with the company's goals and objectives;",
      "Collaborate with the sales team to develop effective sales and marketing campaigns;",
      "Manage and oversee the company's marketing budget;",
      "Conduct market research to identify opportunities for growth and customer needs;",
      "Create and manage content marketing initiatives to drive traffic, engagement, and leads;",
      "Develop and manage marketing automation programs;",
      "Manage the company's website, social media, and other digital marketing channels;",
      "Measure and report on the effectiveness of marketing campaigns;",
      "Identify trends and insights, and optimize spend and performance based on those insights; and",
      "Stay up-to-date with the latest trends and best practices in marketing.",
    ],
    key_qualifications: [
      "Bachelor's degree in marketing, business administration, or a related field;",
      "5+ years of experience in marketing, with a focus on digital marketing;",
      "Strong understanding of marketing automation and lead generation strategies;",
      "Excellent written and verbal communication skills;",
      "Proven track record of developing and executing successful marketing campaigns;",
      "Ability to think creatively and strategically;",
      "Strong project management skills and attention to detail;",
      "Experience with marketing analytics and reporting; and",
      "Proficiency in digital marketing tools such as Google Analytics, SEO, and social media platforms.",
    ],
    working_hours: "Full-time",
    location: "Kwun Tong",
    salary: "Competitive Salary Package",
    skills: [
      "Strong leadership skills",
      "Excellent communication skills",
      "Experience with developing and executing successful marketing campaigns",
      "Knowledge of the latest marketing trends and technologies",
      "Experience with market research and analysis",
      "Experience with digital marketing channels such as social media, email marketing, and SEO/SEM",
      "Ability to manage a team and work collaboratively with cross-functional teams",
    ],
  },
  {
    title: "Blockchain Developer",
    description:
      "We are seeking a highly skilled Blockchain Developer to join our team. The ideal candidate should have experience in designing and developing secure, decentralized blockchain applications. The role will involve working closely with our development team to implement blockchain solutions for our clients. The successful candidate will have a passion for emerging technologies, be able to work in a fast-paced environment and have excellent problem-solving skills.",
    responsibilities: [
      "Design, develop and implement secure, decentralized blockchain applications;",
      "Collaborate with cross-functional teams to identify and prioritize key features for blockchain solutions;",
      "Research and stay up-to-date with emerging blockchain technologies;",
      "Develop smart contracts and decentralized applications (dApps) using Ethereum or other blockchain platforms;",
      "Implement security measures to protect blockchain solutions against hacking and other cyber-attacks; and",
      "Test and deploy blockchain applications on various blockchain networks.",
    ],
    key_qualifications: [
      "Bachelor’s or Master’s degree in Computer Science or related field;",
      "At least 2 years of experience in blockchain development;",
      "Proficient in programming languages such as Solidity, JavaScript, Java or Python;",
      "Experience with blockchain platforms such as Ethereum, Hyperledger, and Corda;",
      "Familiarity with smart contract development and decentralized application (dApp) development;",
      "Understanding of cryptography and security protocols for blockchain solutions;",
      "Strong problem-solving and analytical skills;",
      "Ability to work in a team environment and collaborate with cross-functional teams; and",
      "Excellent communication skills and ability to explain complex technical concepts to non-technical stakeholders.",
    ],
    working_hours: "Full-time",
    location: "Kwun Tong",
    salary: "Competitive Salary Package",
    skills: [
      "Blockchain Development",
      "Ethereum",
      "Binance Smart Chain",
      "Solidity",
      "Smart Contract Development",
      "Distributed Systems",
      "Cryptocurrency",
      "Decentralized Applications (dApps)",
    ],
  },
  {
    title: "Data Engineer",
    description:
      "We are seeking a highly motivated and experienced Data Engineer to join our team. The Data Engineer will be responsible for designing, building, and maintaining the infrastructure required for optimal extraction, transformation, and loading of data from a wide variety of data sources into our data warehouse. The successful candidate will be a self-starter with a passion for data and the ability to work in a fast-paced environment.",
    responsibilities: [
      "Design and implement scalable data pipelines for processing large volumes of data;",
      "Build and maintain data infrastructure, including data pipelines, data warehouses, and data lakes;",
      "Collaborate with data scientists, analysts, and other stakeholders to understand data requirements and develop solutions;",
      "Optimize data pipelines for performance and efficiency;",
      "Develop and maintain data quality standards and best practices;",
      "Troubleshoot data issues and provide timely resolutions; and",
      "Document data pipelines, infrastructure, and processes.",
    ],
    key_qualifications: [
      "Bachelor's degree in Computer Science, Engineering, or a related field;",
      "3+ years of experience in data engineering or a related field;",
      "Strong programming skills in Python, Java, or other programming languages used in data engineering;",
      "Experience with distributed systems such as Hadoop, Spark, or Kafka;",
      "Proficient in SQL and experience working with relational databases;",
      "Knowledge of data modeling and data warehousing concepts;",
      "Familiarity with cloud platforms such as AWS, Azure, or Google Cloud Platform;",
      "Strong problem-solving skills and ability to work independently; and",
      "Excellent communication skills and ability to collaborate effectively with cross-functional teams.",
    ],
    working_hours: "Full-time",
    location: "Kwun Tong",
    salary: "Competitive Salary Package",
    skills: [
      "Data Engineering",
      "Big Data",
      "Hadoop",
      "Spark",
      "Kafka",
      "Python",
      "SQL",
      "Scala",
      "Airflow",
      "Data Warehousing",
      "Data Pipelines",
    ],
  },
];

export default jobs;
