import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

import groupMeeting from "../../../assets/img/meeting/team_meet_final.png";
import techTeam from "../../../assets/img/meeting/tall-team4.png";

function AboutArea(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 or-2 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Get To Know</span>
                <h2>About Us</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>
                  We do design, code &amp; develop Software finally launch.
                </h3>
                <p>
                  At CoolBlock, our vision is to revolutionize industries by
                  implementing Blockchain technology and AI for increased
                  efficiency and transparency. With a team of experts
                  experienced in developing user-friendly web and app solutions,
                  E-commerce platforms, and CRM platforms, we specialize in
                  optimizing the use of Blockchain technology and AI in supply
                  chain management, cybersecurity, and asset tokenization
                  domains.
                </p>

                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div className="company-logo">
                    <img
                      src={`${process.env.PUBLIC_URL} ${props.image}`}
                      alt="about-images"
                      style={{
                        // "border-radius": "50%",
                        width: "30%",
                        height: "30%",
                      }}
                    />
                  </div>
                  <strong>#1</strong>
                  <h4>
                    Best Creative IT Agency And Solutions{" "}
                    <span>Since 2022.</span>
                  </h4>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 or-1 wow animate fadeIn img-video-area"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="about-right">
                <div className="banner-1">
                  <img
                    src={groupMeeting}
                    alt="about-images"
                    style={{ height: "380px" }}
                  />
                </div>
                <div className="banner-2">
                  <img src={techTeam} alt="about-images" />
                  <div className="banner2-inner">
                    <div className="play">
                      <div
                        className="video-popup"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fas fa-play" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="4ZxzqiAh3WA"
          autoplay={1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutArea;
