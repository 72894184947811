import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                Our company offers a wide range of services including UI/UX
                design, web design, app design, software development, and Web
                3.0 solutions to help businesses succeed in the digital world.
              </p>
            </div>
          </div>

          <div className="row g-4">
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image="/img/icons/service-icon-2.png"
                title="UI/UX Design"
                details="We create visually appealing and user-friendly interfaces that enhance user experience."
              />
            </div>

            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image="/img/icons/service-icon-1.png"
                title="Web Design"
                details="Our team of experts designs customized websites that are responsive, visually appealing and user-friendly."
              />
            </div>

            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image="/img/icons/service-icon-6.png"
                title="App Design"
                details="We design visually stunning and easy-to-use mobile applications for both iOS and Android platforms."
              />
            </div>

            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image="/img/icons/service-icon-3.png"
                title="Software Development"
                details="Our team develops robust and scalable software solutions that cater to your business needs."
              />
            </div>

            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image="/img/icons/service-icon-5.png"
                title="Web 3.0 Solutions"
                details="We provide innovative and cutting-edge solutions using decentralized technologies for Web 3.0 applications."
              />
            </div>

            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={6}
                image="/img/icons/service-icon-4.png"
                title="Employment Agency"
                details="We help connect top talent with companies by providing recruitment and staffing services."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
