import React from "react";

import groupMeeting from "../../../assets/img/meeting/team_meet.png";

function HistoryArea() {
  return (
    <>
      <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>History</span>
              <h2>CoolBlock History</h2>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-1.jpg"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2020</span>
                  <h4>
                    We Are Plan To Start with Our Customised Software Solutions
                  </h4>
                  <p>
                    We understand that every business is unique and has its own
                    set of challenges and requirements. That's why we are
                    committed to delivering customized software solutions that
                    address the specific needs of our clients. Whether it's
                    developing a custom CRM platform, creating a custom
                    E-commerce solution, or developing a web or mobile
                    application from scratch, we have the expertise and
                    experience to deliver high-quality solutions that meet your
                    business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2021</span>
                  <h4>We Work Hard for design</h4>
                  <p>
                    We understand the importance of software design for
                    businesses in the digital age. That's why we work tirelessly
                    to deliver customized software design solutions that meet
                    the unique needs and objectives of our clients.
                  </p>
                  <br />
                  <p>
                    Our team of experts has years of experience in software
                    design, including web design, UI/UX design, and software
                    development. We leverage the latest design trends and
                    techniques to create intuitive and engaging interfaces that
                    improve user satisfaction and retention.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={groupMeeting} alt="history-img" />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-3.jpg"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2022</span>
                  <h4>We Start CoolBlock</h4>
                  <p>
                    we have a passion for technology and a desire to help
                    businesses succeed in the digital world. That's why we
                    started our software development business - to provide
                    customized solutions that help businesses thrive in the
                    digital age.
                  </p>
                  <br />
                  <p>
                    We recognized that businesses of all sizes and industries
                    needed technology solutions that could help them improve
                    efficiency, productivity, and customer engagement. We also
                    saw an opportunity to leverage our expertise in software
                    development to create innovative solutions that could
                    address some of the most pressing challenges facing
                    businesses today.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2024</span>
                  <h4>More Effort On Blockchain Solutions</h4>
                  <p>
                    Blockchain technology has emerged as a powerful solution to
                    many of the challenges facing businesses in the digital age.
                    Its decentralized, tamper-proof ledger system provides a
                    secure and transparent way to store and exchange data and
                    assets, making it an attractive solution for industries
                    ranging from finance and supply chain management to
                    healthcare and real estate.
                  </p>
                  <br />
                  <p>
                    We recognize the importance of blockchain technology and its
                    potential to transform industries. That's why we've made it
                    a priority to develop expertise in blockchain solutions,
                    including supply chain management, cybersecurity, and asset
                    tokenization.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-4.png"}
                  alt="history-img"
                  style={{
                    height: "360px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoryArea;
