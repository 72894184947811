import React from "react";
import coolblockAddress from ".././../../assets/img/coolblock-address.png";

function ContactWrapper() {
  return (
    <>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <span>Get in touch</span>
                  <h2>Contact us if you have more questions.</h2>
                </div>
                <ul className="social-follow">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=100087318709061&mibextid=LQQJ4d"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://twitter.com/coolblock6?s=11&t=dD5dd8JZpBo6t80p0XNJ_g"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.pinterest.com/"
                    >
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/coolblocktech/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Location</h3>
                      <p>
                        <u>Registered Address:</u> <br />
                        Unit 12, 21/F., <br />
                        Wayson Commercial Building, <br />
                        No.28 Connaught Road West, <br />
                        Sheung Wan <br />
                        Hong Kong
                      </p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Whatsapp</h3>
                      <a href="tel:64478620">+852 6447 8620</a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                      <a href="tell:info@example.com">
                        enquiries@coolblocktech.net
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <img src={coolblockAddress} alt="coolblock-address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactWrapper;
