import React from "react";
import AnimateLine from "../../common/AnimateLine";
import SubscribArea from "../../common/SubscribArea";
import AboutArea from "./AboutArea";
import FeaturesArea from "./FeaturesArea";
import HeroArea from "./HeroArea";
import PartnarArea from "./PartnarArea";
import ServiceArea from "./ServiceArea";

// Images:
import logo from "../../../assets/img/logo.png";

function HomePageOne() {
  return (
    <>
      <div className="coolblock-wrap">
        <AnimateLine />
        <HeroArea />
        <AboutArea image={logo} />
        <FeaturesArea />
        <ServiceArea />
        <PartnarArea />
        <SubscribArea />
      </div>
    </>
  );
}

export default HomePageOne;
