import React from 'react'

function ServiceDetailsItems() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>01</span>Brainstroming &amp; Wirefarme
                </h4>
                <p>
                  This is the first step in the software development process,
                  where the development team works closely with the client to
                  identify the project requirements, define the scope of work,
                  and create a wireframe or prototype of the project. This stage
                  involves ideation, research, and collaboration to ensure that
                  the project's goals and objectives are clear and that everyone
                  is on the same page. The wireframe or prototype helps
                  visualize the project's structure, layout, and user interface,
                  which serves as the foundation for the rest of the development
                  process.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + '/img/blog/thumb-1.jpg'}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + '/img/blog/thumb-2.jpg'}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>02</span>UI Design
                </h4>
                <p>
                  User Interface (UI) Design is the process of designing the
                  user interface of the software application. It involves
                  creating a visually appealing and intuitive design that
                  enables users to interact with the application seamlessly. The
                  UI design team considers various factors such as user
                  experience, branding, and accessibility while designing the
                  application. They create a style guide that defines the
                  application's color scheme, typography, and layout. Once the
                  UI design is approved, the development team proceeds to the
                  next step of the software development process.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>03</span>Final Developing &amp; Testing
                </h4>
                <p>
                  This is the final stage of the software development process,
                  where the development team builds and tests the application.
                  The development team translates the wireframes and UI designs
                  into a working software application. They write code,
                  integrate APIs, and ensure that the application meets the
                  requirements outlined in the previous stages. Once the
                  development phase is complete, the quality assurance team
                  takes over to test the application. They perform functional,
                  performance, and security tests to ensure that the application
                  is free of errors and meets the client's specifications. After
                  the testing phase is complete, the application is ready for
                  deployment.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + '/img/blog/thumb-3.jpg'}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsItems
