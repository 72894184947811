import React from 'react'

function FaqWrap() {
  return (
    <>
      <section className="faqs-area sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="sidebar-tab">
                <div className="tabs">
                  <a href="#general-tab" className="tab-link active">
                    General
                  </a>

                  <a href="#process-tab" className="tab-link active">
                    Software Development Process
                  </a>

                  <a href="#services-tab" className="tab-link active">
                    Services
                  </a>

                  <a href="#project-mgt-tab" className="tab-link active">
                    Project Management
                  </a>

                  <a href="#pricing-tab" className="tab-link active">
                    Pricing and Quotes
                  </a>

                  <a href="#contact-tab" className="tab-link active">
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="sidebar-tab">
                <div className="questions-form">
                  <h4>HAVE QUESTION?</h4>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    action="#"
                    method="post"
                  >
                    <input type="text" name="name" placeholder="Your Name :" />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email :"
                    />
                    <input
                      type="text"
                      name="number"
                      placeholder="Phone Number :"
                    />
                    <input type="text" name="subject" placeholder="Subject :" />
                    <textarea
                      name="message"
                      cols={30}
                      rows={10}
                      placeholder="Write Message :"
                      defaultValue={''}
                    />
                    <input type="submit" defaultValue="send now" />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="faqs-tabs">
                <div className="faqs" id="general-tab">
                  <h4>General</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. What services does your software development
                          company offer?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Our company offers a range of software development
                            services, including E-commerce, mobile app
                            development, Web 3.0 projects, and CRM platform
                            development.
                          </p>
                          <br />
                          <li>
                            <b>E-commerce:</b> Our E-commerce service provides
                            customized solutions for businesses looking to sell
                            their products online. We offer end-to-end
                            development services, including user-friendly web
                            design, secure payment gateway integration, and
                            efficient inventory management systems. Our team of
                            experts ensures that your online store is optimized
                            for search engines and mobile devices, so you can
                            reach a wider audience and increase your revenue..
                          </li>
                          <br />
                          <li>
                            <b>Mobile app development:</b> We specialize in
                            developing high-quality mobile applications that
                            meet your business requirements. Our team of
                            experienced developers uses the latest technologies
                            and development frameworks to create user-friendly
                            and engaging mobile apps for both Android and iOS
                            platforms. From ideation to design and deployment,
                            we offer end-to-end services to ensure that your app
                            is functional, efficient, and scalable..
                          </li>
                          <br />
                          <li>
                            <b>Web 3.0 projects:</b> Our Web 3.0 service focuses
                            on building decentralized applications (dApps) using
                            blockchain technology. Our team has expertise in
                            developing smart contracts, decentralized finance
                            (DeFi) applications, non-fungible token (NFT)
                            marketplaces, and more. We utilize the power of
                            decentralized networks to create secure,
                            transparent, and decentralized applications that can
                            revolutionize the way businesses operate..
                          </li>
                          <br />
                          <li>
                            <b>CRM platform development:</b> Our CRM platform
                            development service provides businesses with an
                            efficient solution to manage customer relationships.
                            We create customized CRM platforms that can be
                            integrated with other business systems, such as
                            sales and marketing tools, to provide a
                            comprehensive solution for managing customer
                            interactions. Our CRM platforms are designed to
                            improve customer engagement, increase sales
                            productivity, and enhance business efficiency..
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. What industries do you specialize in?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We work with clients across a range of industries,
                          including retail, healthcare, finance, and more.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          03. Where are you located?
                        </button>
                      </span>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We are located in Hong Kong.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqs" id="process-tab">
                  <h4>Software Development Process</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. What is your approach to software development?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            We use agile development methodologies to ensure
                            that our software development process is flexible,
                            efficient, and responsive to client needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. How do you ensure quality in your software
                          development process?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We prioritize quality at every stage of the software
                          development process, from requirements gathering to
                          testing and deployment. Our team uses industry best
                          practices to ensure that the software we deliver meets
                          the highest standards of quality and performance.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          03. How do you stay up-to-date with the latest
                          industry trends and technologies?
                        </button>
                      </span>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We prioritize ongoing learning and development to stay
                          up-to-date with the latest industry trends and
                          technologies. Our team members attend conferences,
                          participate in online courses and webinars, and engage
                          in peer-to-peer learning to stay informed about the
                          latest developments in software development.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqs" id="services-tab">
                  <h4>Services</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. What is your approach to E-commerce development?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            We work closely with clients to understand their
                            business needs and goals, and we use our expertise
                            in E-commerce development to create customized
                            solutions that drive sales and improve customer
                            experiences.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. How do you approach mobile app development?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We use a user-centered design approach to mobile app
                          development, with a focus on delivering intuitive,
                          engaging, and effective mobile experiences that meet
                          our clients' business goals.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          03. What is your approach to Web 3.0 projects?
                        </button>
                      </span>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We work closely with clients to understand their
                          vision for their Web 3.0 project, and we use
                          cutting-edge technologies such as blockchain and
                          decentralized applications to create innovative and
                          impactful solutions.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          04. How do you approach CRM platform development?
                        </button>
                      </span>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We work closely with clients to understand their
                          business needs and goals, and we use our expertise in
                          CRM platform development to create customized
                          solutions that streamline workflows, improve
                          communication, and drive business growth.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqs" id="project-mgt-tab">
                  <h4>Project Management</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. How do you handle changes in project scope or
                          requirements?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            We understand that project requirements can change
                            over time, and we are prepared to adapt to those
                            changes. We work closely with clients to understand
                            their needs and priorities, and we use agile
                            development methodologies to ensure that changes can
                            be accommodated as efficiently as possible.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. Can I speak with a project manager directly?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Yes, you can speak with a project manager directly to
                          discuss your project in detail. Our project managers
                          are available to answer any questions you may have and
                          provide you with guidance and support throughout the
                          software development process.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqs" id="pricing-tab">
                  <h4>Pricing and Quotes</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. How do I get a quote for my project?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            To get a quote for your project, you can contact us
                            through our website or by phone. Our team will work
                            with you to understand your project requirements and
                            provide you with a customized quote based on factors
                            such as project scope, timeline, and complexity.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. What information should I provide when requesting
                          a quote?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          When requesting a quote, it is helpful to provide as
                          much detail as possible about your project, including
                          your business goals, target audience, and any specific
                          requirements or features you may have in mind. This
                          will help us provide you with a more accurate quote.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          03. How does your pricing work?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Our pricing is customized for each project, based on
                          factors such as project scope, timeline, and
                          complexity. We provide transparent and detailed
                          pricing information to our clients, and we work
                          closely with them to ensure that their project stays
                          within budget.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          04. Do you offer fixed-price or hourly pricing?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We offer both fixed-price and hourly pricing,
                          depending on the needs of the project. Our team will
                          work with you to determine which pricing model is best
                          suited for your project.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          05. Are there any hidden costs or fees?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          No, we do not have any hidden costs or fees. We
                          provide transparent pricing information upfront, and
                          we work closely with clients to ensure that there are
                          no unexpected costs or surprises.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqs" id="contact-tab">
                  <h4>Contact Us</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. How can I contact your software development
                          company?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            You can contact us through our website, by phone, or
                            by email. Our team is always available to answer any
                            questions you may have and provide you with guidance
                            and support throughout the software development
                            process.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. Can I schedule a consultation with your team?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Yes, you may arrange a consultation with our team to
                          delve into your project details. You can reach us via
                          Whatsapp at (852) 6447 8620 or send an email to
                          enquiries@coolblocktech.net. Our team is always
                          accessible to address any queries you might have and
                          offer assistance and advice throughout the software
                          development journey.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          03. What is the best way to reach your team?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          To get in touch with our team, the most effective
                          means of communication is through Whatsapp at (852)
                          6447 8620 or by emailing us at
                          enquiries@coolblocktech.net. Our team is readily
                          accessible to address any inquiries you may have and
                          offer valuable guidance and support throughout the
                          software development process.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FaqWrap
