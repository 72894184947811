import React from "react";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

// import plazaPremiumLounge from "../../../assets/img/partner/plaza-premium-lounge.png";
import modernFurniture from "../../../assets/img/partner/modern-furniture.png";
import korStore from "../../../assets/img/partner/kor-store.png";
import ywConsulting from "../../../assets/img/partner/yw-consulting.png";
import lauraBeauty from "../../../assets/img/partner/laura-beauty.png";
import sweetTech from "../../../assets/img/partner/sweet-tech.webp";
import oliviaTutoring from "../../../assets/img/partner/olivia-tutoring.png"

SwiperCore.use([Navigation, Autoplay]);

function PartnarArea() {
  const partnarSlider = {
    loop: true,
    slidesPerView: 5,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  };
  return (
    <>
      <section className="our-partner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="sec-title white layout2">
                <span>Satisfied Client</span>
                <h2>Our Partner</h2>
                <div className="-partnerslider-navigator">
                  <div className="swiper-button-prev-c">
                    <i className="bi bi-chevron-left" />
                  </div>
                  <div className="swiper-button-next-c">
                    <i className="bi bi-chevron-right" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <Swiper {...partnarSlider} className="swiper partner-slider">
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={oliviaTutoring}
                        alt="partnar-img"
                        style={{
                          height: "120px",
                          width: "150px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={modernFurniture}
                        alt="partnar-img"
                        style={{
                          height: "120px",
                          width: "150px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={korStore}
                        alt="partnar-img"
                        style={{
                          height: "120px",
                          width: "150px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={ywConsulting}
                        alt="partnar-img"
                        style={{
                          height: "120px",
                          width: "150px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={lauraBeauty}
                        alt="partnar-img"
                        style={{
                          height: "120px",
                          width: "150px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img
                        src={sweetTech}
                        alt="partnar-img"
                        style={{
                          height: "120px",
                          width: "150px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PartnarArea;
