import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";

import jobs from "../../../data/jobs";

const JoinUs = () => {
  return (
    <div className="joinus-grid">
      <AnimateLine />
      <Breadcrumb pageTitle="Join us" pageName="Join us" />

      <div className="joinus-intro">
        <p>
          <b>CoolBlock</b> is always looking for talented and passionate
          individuals to join our team. If you are looking for a dynamic and
          challenging work environment where you can apply your skills and make
          a difference, we encourage you to explore our current openings.
        </p>
        <br />
        <p>
          We offer competitive salaries, flexible working hours, and a
          comprehensive benefits package. Our team consists of experts in
          various fields, including web design, UI/UX design, software
          development, and blockchain technology. We value diversity,
          creativity, and innovation, and we are committed to providing our
          employees with opportunities for growth and career development.
        </p>
        <br />

        <p>
          To become a part of our team, kindly peruse the list of our current
          job vacancies provided below and send us your cover letter and resume
          via email at <b>hr@coolblocktech.net</b>. We anticipate your response
          with enthusiasm!
        </p>
      </div>

      {jobs.map((job) => (
        <div className="job-card-container" key={job.id}>
          <div className="job-card">
            <h3>{job.title}</h3>
            <p>{job.description}</p>
            <br />
            <p>
              <b>Responsibilities :</b>

              {job.responsibilities.map((responsibility) => (
                <li key={responsibility}>{responsibility}</li>
              ))}
            </p>
            <br />
            <p>
              <b>Key qualifications :</b>
              {job.key_qualifications.map((qualification) => (
                <li key={qualification}>{qualification}</li>
              ))}
            </p>
            <br />
            <p>
              <b>{job.salary}</b>
            </p>
            <br />

            <p className="skill-display">
              <b>Required skills : </b>
              {job.skills.map((skill) => (
                <p key={skill} className="skills-space">
                  {skill}
                </p>
              ))}
            </p>
          </div>
        </div>
      ))}

      <SubscribArea />
    </div>
  );
};

export default JoinUs;
