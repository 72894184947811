import React from "react";
import tallGroupMeetingimg from "../../../assets/img/meeting/tall-team2.png"
import techTeam from "../../../assets/img/meeting/team_meet1.png";


function WhyChooseUs() {
  return (
    <>
      <section
        className="why-choose sec-mar wow animate fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img
                    src={tallGroupMeetingimg}
                    alt="why-choose images"
                  />
                </div>
                <div className="choose-banner2">
                  <img
                    src={techTeam}
                    alt="why-choose images"
                    style={{height: "220px"}}
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/img/why-3.jpg"}
                    alt="why-choose images"
                  />
                </div>
                <div className="years">
                  <h5>10+</h5>
                  <span>Years</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h2>
                    Choose Our Software Development Services to Take Your
                    Business to the Next Level
                  </h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">30</span>
                    <sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">20</span>
                    <sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">20</span>
                    <sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>
                  We offer a wide range of software development solutions
                  designed to help businesses thrive in the digital world. Our
                  team of experts has years of experience working on various
                  software development projects, including website and app
                  development, E-commerce and CRM platforms, and blockchain
                  projects.
                </p>
                <br />
                <p>
                  What sets us apart from our competitors is our commitment to
                  delivering customized solutions that meet the unique needs of
                  our clients. We work closely with each client to understand
                  their specific objectives and goals, and we leverage the
                  latest technologies and best practices to deliver high-quality
                  solutions that exceed their expectations.
                </p>
                <div className="buttons-group">
                  <span>24/7 Support</span>
                  <span>Unique Design</span>
                  <span>Clean Code Develope</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChooseUs;
