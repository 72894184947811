import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Case Study</span>
              <h2>Project</h2>
              <p>
                Our projects include a diverse range of software development
                solutions that cater to businesses of all sizes and industries.
                From website and app development to E-commerce and CRM
                platforms, we deliver customised solutions that meet the
                specific needs of our clients.
              </p>
            </div>
          </div>
          <ProjectCardFilter />
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
