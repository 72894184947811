import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import WOW from "wowjs";
import logo from "../../assets/img/logo.png";

function Header() {
  const [sidebar, setSidebar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  const handleStickyHeader = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  const handleSidebarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "service":
        return { activeMenu: "service" };
      case "project":
        return { activeMenu: "project" };
      case "page":
        return { activeMenu: "page" };
      default:
        return { activeMenu: "" };
    }
  }

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    window.addEventListener("scroll", handleStickyHeader);
    return () => {
      window.removeEventListener("scroll", () => handleStickyHeader);
    };
  }, []);

  return (
    <>
      <header
        className={`${"header-area position_top"} ${isSticky ? "sticky" : ""}`}
        ref={ref}
      >
        <div className="site-logo">
          <div className="logo">
            <Link to={"/"} onClick={scrollTop}>
              <img
                style={{
                  "border-radius": "50%",
                  width: "45%",
                  height: "45%",
                  "margin-top": "6px",
                  "margin-bottom": "1px",
                  "margin-left": "4px",
                  display: "block", // set display to block by default
                  "@media (max-width: 768px)": {
                    display: "none", // hide image on screens smaller than 768px
                  },
                }}
                src={logo}
                alt="logo-img"
              />
            </Link>
          </div>
        </div>

        <div className="main-menu">
          <nav className={sidebar === 1 ? "main-nav slidenav" : "main-nav"}>
            <div className="mobile-menu-logo">
              <Link to={"/"} onClick={scrollTop}>
                <img
                  style={{
                    "border-radius": "50%",
                    width: "35%",
                    height: "35%",
                    "margin-top": "2px",
                    "margin-bottom": "2px",
                  }}
                  src={logo}
                  alt="images"
                />
              </Link>
              <div className="remove" onClick={handleSidebarMenu}>
                <i className="bi bi-plus-lg" />
              </div>
            </div>

            <ul>
              <li>
                <NavLink
                  to={"/"}
                  onClick={scrollTop}
                  className={state.activeMenu === "homeOne" ? "active" : ""}
                >
                  Home
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/about`}
                  onClick={scrollTop}
                >
                  About Us
                </NavLink>
              </li>

              <li>
                <Link to={"#"} onClick={() => dispatch({ type: "service" })}>
                  Service
                </Link>
                <i className="bi bi-chevron-down" />
                <ul
                  className={
                    state.activeMenu === "service"
                      ? "sub-menu d-block"
                      : "sub-menu d-none"
                  }
                >
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/service`}
                      onClick={scrollTop}
                    >
                      Service
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Service Details
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/faq`}
                  onClick={scrollTop}
                >
                  FAQs
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/join-us`}
                  onClick={scrollTop}
                >
                  Join Us
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/contact`}
                  onClick={scrollTop}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>

            <div className="get-qoute d-flex justify-content-center d-lg-none d-block pt-50">
              <div className="cmn-btn">
                <div className="line-1"></div>
                <div className="line-2"></div>
                <Link
                  to={`${process.env.PUBLIC_URL}/contact`}
                  onClick={scrollTop}
                >
                  Get A Quote
                </Link>
              </div>
            </div>
          </nav>
        </div>

        <div className="nav-right">
          <div className="get-qoute">
            <div className="cmn-btn">
              <div className="line-1" />
              <div className="line-2" />
              <Link
                to={`${process.env.PUBLIC_URL}/contact`}
                onClick={scrollTop}
              >
                Get A Quote
              </Link>
            </div>
          </div>
          <div className="mobile-menu">
            <Link to="#" onClick={handleSidebarMenu} className={"cross-btn"}>
              <span className="cross-top" />
              <span className="cross-middle" />
              <span className="cross-bottom" />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
